import React, { Component, useEffect, useState } from 'react';
import LoginPage from './LoginPage';
import { Route, BrowserRouter, Routes, useNavigate, useLocation } from "react-router-dom";
import { config } from './Constants';
import Cookies from 'js-cookie'
import 'react-toastify/dist/ReactToastify.css';
import { useTranslation } from "react-i18next";
import EmailVerificationPage from './EmailVerificationPage';
import SetupFlow from './SetupPages/SetupFlow';
import RegisterPage from './RegisterPage'
import App from './App'
import { logout } from './utils/LoadInformation';
import { TasLoader, checkConnectivity } from './utils/general';
import posthog from 'posthog-js'
import { ToastContainer, toast } from 'react-toastify';

const ResetPasswordPage = React.lazy(() => import('./ResetPassword'));

function loadMetadata(restaurantId, callback) {
	fetch(`${config.url.PUBLIC}/restaurants/${restaurantId}/metadata.json`)
		.then(resp => {
			if (resp.status === 200) resp.json().then(data => {
				if (callback) callback(data)
			})
		})
}

const noLoginRequiredLocations = ['/login', '/register', '/reset_password', '/verify-email']

function AppRoutes({ restaurantId, onLogin, pathRequested, doSetup, refreshMetadata, prebuiltMenu, onLogout, metadata, redirectTo }) {
	const [firstRender, setFirstRender] = useState(true)
	const navigate = useNavigate();
	const location = useLocation();

	useEffect(() => {
		setFirstRender(false)
	}, [])

	useEffect(() => {
		if (restaurantId === '' &&
			!noLoginRequiredLocations.some(ele => location.pathname.startsWith(ele))
		) navigate('/login')
	}, [restaurantId])

	useEffect(() => {
		if (!metadata) return

		if (metadata.setup !== 'completed') {
			navigate('/setup')
		} else if (redirectTo) {
			navigate(redirectTo)
		} else if (location.pathname.startsWith('/setup')) {
			navigate('/business-info')
		}

	}, [redirectTo, metadata])

	if (firstRender) return <></> // let the navigate apply

	return <Routes>
		<Route exact path="/register" element={<RegisterPage />} /> { /** TODO: check if it needs props */}
		<Route exact path="/reset_password" element={
			<React.Suspense fallback={<TasLoader />}>
				<ResetPasswordPage />
			</React.Suspense>
		} />
		<Route exact path="/login" element={
			<LoginPage
				onLogin={onLogin}
				restaurantId={restaurantId} // TODO: remove?
				redirectPath={pathRequested} /> //TODO: remove?
		} />
		<Route exact path="/verify-email/:verificationCode" element={
			<EmailVerificationPage onValidate={doSetup} />
		} />
		<Route exact path="/setup" element={
			<SetupFlow
				restaurantId={restaurantId}
				refreshMetadata={refreshMetadata}
				prebuilt={prebuiltMenu}
				onLogout={onLogout}
				metadata={metadata} />
		} />
		<Route path="/*" element={
			<App
				// restaurantId={restaurantId} // TODO: read from metadata inside App
				onLogout={onLogout}
				onLogin={onLogin}
				pathname={redirectTo || pathRequested}
				// {...props} TODO: check commenting is ok
				search={window.location.search}
				defaultMetadata={metadata} />
		} />
	</Routes >
}

function AppRouter() {
	const [metadata, setMetadata] = useState(null)
	const [restaurantId, setRestaurantId] = useState(null)
	const [redirectTo, setRedirectTo] = useState(null)
	const [pathRequested, setPathRequested] = useState(window.location.pathname)
	const [prebuiltMenu, setPrebuiltMenu] = useState(null)
	const { i18n } = useTranslation('app');

	useEffect(() => {
		posthog.init('phc_1NUABBjtoBfQVsWkE6AxAdMwBMn9FCVFYt3LTOBKpjO', { api_host: 'https://eu.posthog.com' })

		checkConnectivity().then(isConnected => {
			if (!isConnected) toast.error("No internet connection.")
		})
	}, [])

	useEffect(() => {
		if (restaurantId !== null) return

		// Check if the user is already logged in
		fetch(`${config.url.API}/restaurant/id`, {
			method: 'get',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			},
			credentials: "include"
		}).then(resp => {
			if (resp.status === 200) resp.json().then(id => setRestaurantId(id));
			else setRestaurantId('')
		}).catch(e => setRestaurantId(''))
	}, [restaurantId])

	useEffect(() => {
		if (!restaurantId) return

		loadMetadata(restaurantId, (data) => setMetadata(data))
	}, [restaurantId])

	function onLogin(restaurantId, redirectionPath) { // TODO: handle redirectTo better
		const pathToRedirectTo = redirectionPath === '/login' ? '/' : (redirectionPath || '/')

		setRestaurantId(restaurantId)
		loadMetadata(restaurantId, (data) => {
			setMetadata(data)
			setRedirectTo(pathToRedirectTo)
		})
	}

	function refreshMetadata(newMetadata, callback) {
		setMetadata(newMetadata, () => {
			if (callback) callback()
		})
	}

	function onLogout() {
		logout(() => setRestaurantId(''))
	}

	function doSetup(id, lang, prebuilt) {
		i18n.changeLanguage(lang)

		const current = new Date();
		const nextYear = new Date();
		nextYear.setFullYear(current.getFullYear() + 1);

		Cookies.set("lang", lang, { domain: config.cookies.domain, expires: nextYear })
		setRestaurantId(id)
		setPrebuiltMenu(prebuilt)
	}

	// We first attempt to load the user id. Show loader when waiting.
	if (restaurantId === null || (
		restaurantId && metadata === null
	)) {
		return <></>
	}

	return <BrowserRouter>
		<ToastContainer />
		<AppRoutes
			restaurantId={restaurantId}
			onLogin={onLogin}
			pathRequested={pathRequested}
			doSetup={doSetup}
			refreshMetadata={refreshMetadata}
			prebuiltMenu={prebuiltMenu}
			onLogout={onLogout}
			metadata={metadata}
			redirectTo={redirectTo} />
	</BrowserRouter>
}

// const AppRouterTranslated = withTranslation('app')(AppRouter);

export default AppRouter;